import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          defaultDescription,
          siteUrl,
          defaultImage,
          author: { twitter },
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
        twitterUserName: twitter || '',
        article: article || null,
      }
      return (
        <Helmet>
          {/* -- Primary Meta Tags -- */}
          {seo.title && <title>{seo.title}</title>}
          {seo.title && <meta property="title" content={seo.title} />}
          {seo.description && (
            <meta property="description" content={seo.description} />
          )}
          {/* -- Open Graph / Facebook -- */}
          {seo.title && <meta property="og:title" content={seo.title} />}
          {seo.description && (
            <meta property="og:description" content={seo.description} />
          )}
          {seo.url && <meta property="og:url" content={seo.url} />}
          <meta property="og:type" content={article ? 'article' : 'website'} />}
          {seo.article &&
            seo.article.publishedTime && (
              <meta
                property="article:published_time"
                content={seo.article.publishedTime}
              />
            )}
          {seo.image && <meta property="og:image" content={seo.image} />}
          {/* -- Twitter -- */}
          <meta property="twitter:card" content="summary_large_image" />
          {seo.url && <meta property="twitter:url" content={`${seo.url}`} />}
          {seo.title && <meta property="twitter:title" content={seo.title} />}
          {seo.description && (
            <meta property="twitter:description" content={seo.description} />
          )}
          {seo.twitterUserName && (
            <meta property="twitter:creator" content={seo.twitterUserName} />
          )}
          {seo.image && <meta property="twitter:image" content={seo.image} />}
        </Helmet>
      )
    }}
  />
)

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: featuredImage
        author {
          twitter
        }
      }
    }
  }
`
